#cookieblock {
  z-index: 90000; }
  #cookieblock.popup {
    position: absolute; }
  #cookieblock.inline, #cookieblock.inline #cookieblock__banner, #cookieblock.inline #cookieblock__popup {
    position: relative; }
  #cookieblock__banner {
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 2px;
    color: #222;
    max-width: 26rem;
    margin: 24px auto;
    position: fixed;
    z-index: 100;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: rgba(0, 0, 0, 0.08) 0 8px 24px; }
    #cookieblock__banner__wrapper {
      padding: 15px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      flex-direction: column; }
      #cookieblock__banner__wrapper > div:first-child {
        margin-bottom: 1rem;
        line-height: 1.6; }
  #cookieblock__popup {
    box-sizing: border-box;
    display: none;
    background: #fff;
    border-radius: 2px;
    color: #222;
    padding: 2rem;
    width: calc(100% - map-get($popupConsent, padding ));
    position: fixed;
    top: 1rem;
    z-index: 300;
    max-width: 800px;
    max-height: calc(100vh - map-get($popupConsent, padding )/2);
    overflow-y: auto;
    margin-left: 1rem;
    margin-right: 1rem; }
    @media screen and (min-width: 800px) {
      #cookieblock__popup {
        width: 100%;
        left: calc(50% - 400px);
        margin-left: 0;
        margin-right: 0; } }
    #cookieblock__popup__detailed {
      box-sizing: border-box;
      display: none;
      padding-bottom: 20px; }
    #cookieblock__popup-bg {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.52);
      z-index: 200; }
  #cookieblock a {
    color: #FF4F40; }
    #cookieblock a:hover {
      color: #b1b4bd; }
  #cookieblock table {
    background: #eaeaea;
    border-radius: 2px;
    overflow: hidden;
    width: 100%;
    margin-top: 1rem; }
    #cookieblock table thead {
      background: #3D8199;
      color: #fff; }
    #cookieblock table td {
      padding: .7rem 1rem;
      font-size: 12px;
      vertical-align: top;
      box-sizing: border-box; }
    #cookieblock table tr td {
      border-bottom: 1px solid #300c4f08; }
    #cookieblock table tr:last-child td {
      border-bottom: none; }
    #cookieblock table thead td:nth-child(1),
    #cookieblock table tbody td:nth-child(1) {
      width: 15%;
      text-overflow: ellipsis;
      max-width: 50px;
      overflow: hidden; }
    #cookieblock table thead td:nth-child(2),
    #cookieblock table tbody td:nth-child(2) {
      width: 20%;
      text-overflow: ellipsis;
      max-width: 100px;
      overflow: hidden; }
  #cookieblock .btn {
    display: inline-block;
    cursor: pointer;
    text-transform: uppercase;
    font-family: helvetica, arial, sans-serif;
    font-weight: 700;
    line-height: 40px;
    font-size: 12px;
    min-height: 40px;
    color: #fff;
    letter-spacing: 1px;
    background: #FF4F40;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px, rgba(17, 222, 114, 0.1) 0 2px 3px;
    border-width: 0;
    padding: 0 1.3rem;
    border-radius: 2px;
    outline: none;
    text-decoration: none;
    transition: all .05s linear; }
    #cookieblock .btn-simple {
      font-family: helvetica, arial, sans-serif;
      font-weight: 700;
      line-height: 30px;
      font-size: 10px;
      display: inline-block;
      cursor: pointer;
      min-height: 30px;
      text-transform: uppercase;
      letter-spacing: 1px;
      border-width: 0;
      padding: 0 1.3rem;
      outline: none;
      text-decoration: none;
      color: #222;
      background: transparent; }
      #cookieblock .btn-simple--popup {
        padding: 0; }
        @media screen and (min-width: 600px) {
          #cookieblock .btn-simple--popup {
            padding: 0 1.3rem; } }
      #cookieblock .btn-simple:focus, #cookieblock .btn-simple:hover, #cookieblock .btn-simple:active {
        text-decoration: none; }
    #cookieblock .btn:focus {
      text-decoration: none; }
    #cookieblock .btn:hover {
      text-decoration: none;
      color: #222;
      background: #00ff83;
      box-shadow: 0 5px 9px rgba(0, 0, 0, 0.1), 0 2px 3px rgba(21, 255, 131, 0.15); }
    #cookieblock .btn:active {
      text-decoration: none;
      color: #222;
      background: #00bd61;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(48, 13, 79, 0.15); }
    #cookieblock .btn--dark {
      background: #3D8199;
      color: #fff;
      box-shadow: rgba(0, 0, 0, 0.15) 0 4px 6px, rgba(48, 13, 79, 0.15) 0 2px 3px; }
      #cookieblock .btn--dark:hover {
        background: #4490ab;
        color: #fff;
        box-shadow: 0 5px 9px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(48, 13, 79, 0.15); }
      #cookieblock .btn--dark:active {
        background: #4490ab;
        color: #fff;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(48, 13, 79, 0.15); }
    #cookieblock .btn--light {
      color: #3D8199;
      background: #fff;
      box-shadow: rgba(0, 0, 0, 0.12) 0 3px 6px, rgba(233, 237, 246, 0.12) 0 2px 3px; }
      #cookieblock .btn--light:hover {
        box-shadow: rgba(0, 0, 0, 0.12) 0 5px 9px, rgba(233, 237, 246, 0.12) 0 2px 3px;
        background: #edf1f7;
        color: #3D8199; }
      #cookieblock .btn--light:active {
        box-shadow: rgba(0, 0, 0, 0.12) 0 1px 2px, rgba(233, 237, 246, 0.12) 0 2px 3px;
        background: #dee2eb;
        color: #3D8199; }
    #cookieblock .btn--small {
      min-height: 25px;
      line-height: 25px;
      font-size: 10px;
      padding: 0 8px; }
  #cookieblock .popup__title {
    font-family: helvetica, arial, sans-serif;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 0.03rem;
    color: #FF4F40;
    margin: 0 0 1rem;
    font-size: 32px; }
  #cookieblock .popup__descr {
    font-family: helvetica, arial, sans-serif;
    font-weight: 300;
    line-height: 1.7;
    font-size: 14px;
    color: #222; }
  #cookieblock .popup__ctas {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
    @media screen and (min-width: 600px) {
      #cookieblock .popup__ctas {
        flex-direction: row; } }
  #cookieblock .cookie-details-table {
    display: none;
    width: 100%; }
  #cookieblock .can-toggle {
    position: relative; }
    #cookieblock .can-toggle *,
    #cookieblock .can-toggle *:before,
    #cookieblock .can-toggle *:after {
      box-sizing: border-box; }
  #cookieblock .cookie-details-section {
    border-bottom: 1px solid #dee2eb;
    padding: 1rem 0; }
    #cookieblock .cookie-details-section:first-child {
      padding-top: 0; }
    #cookieblock .cookie-details-section-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: helvetica, arial, sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.03rem;
      line-height: 1.2;
      font-weight: 600;
      color: #FF4F40;
      margin: 0 0 1rem;
      font-size: 32px; }
  #cookieblock .cookie-details-expanse {
    display: inline-block;
    margin-top: 1rem;
    text-decoration: none; }
  #cookieblock .cookie-details-paragraph {
    font-family: helvetica, arial, sans-serif;
    font-weight: 300;
    line-height: 1.7;
    font-size: 14px;
    color: #222; }
  #cookieblock .can-toggle input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0; }
  #cookieblock .can-toggle input[type="checkbox"][disabled] ~ label {
    pointer-events: none; }
    #cookieblock .can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
      opacity: .4; }
  #cookieblock .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
    content: attr(data-unchecked);
    left: 0; }
  #cookieblock .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    content: attr(data-checked); }
  #cookieblock .can-toggle label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    width: 134px; }
    #cookieblock .can-toggle label .can-toggle__switch {
      position: relative;
      transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
      background: #b1b4bd;
      height: 36px;
      flex: 0 0 134px;
      border-radius: 0px; }
      #cookieblock .can-toggle label .can-toggle__switch:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center;
        color: white;
        left: 67px;
        font-size: 12px;
        line-height: 36px;
        width: 67px;
        padding: 0 12px; }
      #cookieblock .can-toggle label .can-toggle__switch:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        background: #fff;
        transform: translate3d(0, 0, 0);
        top: 2px;
        left: 2px;
        border-radius: 0px;
        width: 65px;
        line-height: 32px;
        font-size: 12px;
        transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
        color: #b1b4bd; }
  #cookieblock .can-toggle input[type="checkbox"][disabled] ~ label {
    color: rgba(119, 119, 119, 0.5); }
  #cookieblock .can-toggle input[type="checkbox"]:checked ~ label:hover {
    color: #00de72; }
  #cookieblock .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
    background-color: #00de72; }
    #cookieblock .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
      color: #00de72;
      transform: translate3d(65px, 0, 0); }
